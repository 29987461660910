<template>
  <div>
    <div v-if="isThereData">
      <b-pagination size="sm" align="fill"
                    v-model="currentPage"
                    :total-rows="getTotalItems"
                    :per-page="perPage"
                    :disabled="!getAvailableList"
                    aria-controls="empresas-table"
      ></b-pagination>
    </div>
    <!-- Table -->
    <b-table
      id="empresasTable"
      v-if="getAvailableList"
      :fields="fields"
      :items="getList"
      striped
      bordered
      responsive
      hover
      stacked="lg"
      show-empty
      small
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
      empty-filtered-text="No se encontraron empresas con el criterio de búsqueda escrito"
    >
      <!-- Eliminado -->
      <template #cell(is_deleted)="row">
        <b-badge v-if="row.item.is_deleted" variant="danger">No</b-badge>
        <b-badge v-else variant="light">Sí</b-badge>
      </template>

      <template #cell(rfc)="row">
        <filtered-row :value="row.item.rfc" :filter="filter"/>
      </template>

      <template #cell(razon_social)="row">
        <filtered-row :value="row.item.razon_social" :filter="filter"/>
      </template>

      <template #cell(domicilio_fiscal)="row">
        <filtered-row :value="row.item.domicilio_fiscal" :filter="filter"/>
      </template>

      <template #cell(codigo_postal)="row">
        <filtered-row :value="row.item.codigo_postal" :filter="filter"/>
      </template>

      <template #cell(regimen_fiscal.descripcion)="row">
        <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.regimen_fiscal.descripcion }}
        </span>
      </template>

      <template #cell(uso_cfdi)="row">
        <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.uso_cfdi.descripcion }}
        </span>
      </template>

      <!-- Detalle -->
      <template #cell(show_details)="row">
        <b-button
          @click="row.toggleDetails"
          variant="light"
          class="btn-sm mb-2 btn-block btn-outline-black"
        >
          Detalles
        </b-button>
      </template>

      <!-- Acciones -->
      <template #cell(acciones)="row">
        <div class="d-flex justify-content-center" v-if="!row.item.is_deleted">
          <u-d-controls
            :id="row.item.id"
            editPath="empresaModule/getResourceToEdit"
            deletePath="empresaModule/deleteResource"
            :editPermissions="['actualizar empresas']"
            :deletePermissions="['eliminar empresas']"
            @on-got-item="emitOnGotItem"
          ></u-d-controls>
        </div>
      </template>

      <!-- Acciones -->
      <template #row-details="row">
        <b-card
          class="mb-1"
          border-variant="primary"
          header-bg-variant="primary"
          header-text-variant="white"
          header="Detalle"
          no-body
        >
          <b-card-body>
            <b-card no-body>
              <b-button
                variant="white"
                size="sm"
                class="btn-outline-black"
                @click="row.toggleDetails"
              >
                Cerrar detalle
              </b-button>
            </b-card>
          </b-card-body>
        </b-card>

        <!-- El botón de "ver como", únicamente lo puede usar el usuario con rol "admin" -->
      </template>
    </b-table>
    <loading v-else></loading>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FilteredRow from '@/components/Common/Table/FilteredRow'

const API_MODULE = 'empresaModule'

export default {
  name: 'EmpresaList',

  components: {
    FilteredRow
  },

  props: {
    filter: {
      required: true
    }
  },

  created () {
    this.submit()
  },

  data () {
    return {
      fields: [
        { key: 'rfc', label: 'RFC' },
        { key: 'razon_social', label: 'Razón Social' },
        { key: 'domicilio_fiscal', label: 'Domicilio Fiscal' },
        { key: 'codigo_postal', label: 'Codigo Postal' },
        { key: 'regimen_fiscal.descripcion', label: 'Régimen Fiscal' },
        { key: 'uso_cfdi', label: 'Uso CFDI' },
        { key: 'show_details', label: 'Detalle' },
        { key: 'acciones', label: 'Acciones' }
      ],

      perPage: 10,

      currentPage: 1,

      totalRows: 0,

      filterOn: ['rfc', 'razon_social', 'domicilio_fiscal', 'codigo_postal']
    }
  },

  computed: {
    ...mapGetters(API_MODULE, [
      'getList',
      'getAvailableList',
      'isThereData',
      'getResource',
      'getTotalItems'
    ]),
    ...mapGetters('userModule', [
      'isAuthUserOrCloneUser'
    ])
  },

  methods: {
    async submit () {
      const { error, message } = await this.$store.dispatch(`${API_MODULE}/getResource`)
      this.totalRows = this.getTotalItems

      if (error) this.$notify({ error, message }, 'Empresas')
    },

    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    emitOnGotItem () {
      this.$emit('on-got-item')
    }
  }
}
</script>
