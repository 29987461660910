<template>
  <div>
    <slot name="activator" v-bind:onShowModal="onShowModal">
      <b-button @click="onShowModal" variant="success" size="sm">Crear</b-button>
    </slot>

    <b-modal
      v-model="modal"
      centered
      size="lg"
      scrollable
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      dialog-class="x-modal-md"
    >
      <template #modal-title>
        <span :class="isEditingResource ? 'text-danger' : ''">{{ showFormTitle }}</span>
      </template>

      <x-alert-with-errors
        :error="response.error"
        :title="response.message"
        :errors="response.errors"
      />

      <!-- <b-row v-if="isEditingResource">
        <b-col class="d-flex flex-row">
          <b-badge variant="dark" class="mb-3 mr-2" v-text="getLastAccessText"></b-badge>
          <b-badge variant="success" class="mb-3 mr-2" v-text="getCreationDate"></b-badge>
          <b-badge variant="warning" class="mb-3" v-text="getModificationDate"></b-badge>
        </b-col>
      </b-row> -->

      <b-overlay :show="isLoading">
        <template #overlay>
          <div class="text-center">
            <loading message="Procesando información, por favor espere..." />
          </div>
        </template>

        <FormulateForm
          ref="empresaForm"
          name="empresaForm"
          v-model="formValues"
          :errors="response.errors"
          @submit="submit"
        >
          <div class="card">
            <div class="card-header">
              Información de la empresa
            </div>
            <div class="card-body">
              <b-row>
                <b-col cols="12" md="6">
                  <FormulateInput
                    name="rfc"
                    label="RFC"
                    type="text"
                  />
                </b-col>

                <b-col cols="12" md="6">
                  <FormulateInput
                    name="codigo_postal"
                    label="Código Postal"
                    type="text"
                  />
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="12">
                  <FormulateInput
                    name="razon_social"
                    label="Razón Social"
                    type="text"
                  />
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="12">
                  <FormulateInput
                    name="domicilio_fiscal"
                    label="Domicilio Fiscal"
                    type="text"
                  />
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="6">
                  <FormulateInput
                    name="sat_regimen_fiscal_id"
                    validation="bail|required"
                  >
                    <template #element>
                      <x-form-group title="Régimen fiscal" required>
                        <x-select
                          v-model="formValues.sat_regimen_fiscal_id"
                          dispatchPath="regimenModule/getResourceTipoMoral"
                          getterListPath="regimenModule/getResourceData"
                          :formatListWith="['id', 'descripcion']"
                          :disabled="isLoading">
                        </x-select>
                      </x-form-group>
                    </template>
                  </FormulateInput>
                </b-col>

                <b-col cols="12" md="6">
                  <FormulateInput
                    name="sat_uso_cfdi_id"
                    validation="bail|required"
                  >
                    <template #element>
                      <x-form-group title="Uso CFDI" required>
                        <x-select
                          v-model="formValues.sat_uso_cfdi_id"
                          dispatchPath="usoCfdiModule/getResource"
                          getterListPath="usoCfdiModule/getResourceData"
                          :formatListWith="['id', 'descripcion']"
                          :disabled="isLoading">
                        </x-select>
                      </x-form-group>
                    </template>
                  </FormulateInput>
                </b-col>
              </b-row>
            </div>
          </div>
        </FormulateForm>
      </b-overlay>

      <template #modal-footer>
        <!-- Enviar para crear o actualizar -->
        <div class="w-100">
          <x-form-footer-buttons
            :disabled="$refs.empresaForm ? $refs.empresaForm.hasErrors : false"
            :loading="isLoading"
            :isEditing="isEditingResource"
            :isCreating="!isEditingResource"
            @on-cancel="resetForm"
            @on-create="submit"
            @on-update="submit"
          ></x-form-footer-buttons>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'

const formValues = () => ({
  rfc: '',
  codigo_postal: '',
  razon_social: '',
  domicilio_fiscal: '',
  sat_regimen_fiscal_id: '',
  sat_uso_cfdi_id: ''
})

const response = () => ({
  error: false,
  message: '',
  errors: {}
})

const API_MODULE = 'empresaModule'

export default {
  name: 'EmpresaForm',

  props: {
    value: {
      type: Boolean,
      required: true
    }
  },

  async created () {
    //
    // Obtiene todos los roles del sistema
    await this.$store.dispatch('roleModule/getSystemRoles')
  },

  data () {
    return {
      modal: this.value,

      isLoading: false,

      formValues: formValues(),

      currentRoles: [],

      regimenDispatchPath: 'regimenModule/getResourceTipoFisica',

      response: response()
    }
  },

  computed: {
    //
    // User module
    ...mapState(API_MODULE, ['editingResource']),
    ...mapGetters(API_MODULE, ['isEditingResource']),

    showFormTitle () {
      if (this.isEditingResource) {
        return `Editando empresa: '${this.editingResource.razon_social}'`
      } else {
        return 'Crear nueva empresa'
      }
    },

    getCreationDate () {
      if (!this.isEditingResource) return ''

      return `Creación: ${this.$customFormatDate(this.editingResource.fecha_creacion, 'DD/MMMM/YYYY')}`
    },

    getModificationDate () {
      if (!this.isEditingResource) {
        return ''
      }

      return `Modificación: ${this.$customFormatDate(
        this.editingResource.ultima_modificacion,
        'DD/MMMM/YYYY'
      )}`
    }
  },

  watch: {
    isEditingResource: {
      handler (value) {
        if (value) {
          const { id, rfc, codigo_postal, razon_social, domicilio_fiscal, regimen_fiscal, uso_cfdi } = this.editingResource
          this.formValues.id = id
          this.formValues.rfc = rfc
          this.formValues.razon_social = razon_social
          this.formValues.domicilio_fiscal = domicilio_fiscal
          this.formValues.codigo_postal = codigo_postal
          this.formValues.sat_regimen_fiscal_id = regimen_fiscal.id
          this.formValues.sat_uso_cfdi_id = uso_cfdi.id

          return
        }

        this.resetForm()
      }
    },

    modal: {
      immediate: true,
      handler (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    /**
     * Envía el formulario.
     *
     * Envía el formulario para su creación o su actualización.
     * Dependiendo si la variable "isEditingResource" es verdadera actualizará
     * si no es así entonces mandará crear.
     */
    async submit () {
      this.response = response()
      this.prepareInput()

      this.isLoading = true
      const { error, message, data } = this.isEditingResource
        ? await this.updateEmpresa(this.formValues)
        : await this.createEmpresa(this.formValues)
      this.isLoading = false

      this.$notify({ error, message }, 'Envío de formulario')

      this.response.error = error
      this.response.message = message
      this.response.errors = error ? data.errors : {}

      if (!error) this.resetForm()
    },

    async createEmpresa (form) {
      return await this.$store.dispatch(`${API_MODULE}/createResource`, form)
    },

    async updateEmpresa (form) {
      return await this.$store.dispatch(`${API_MODULE}/updateResource`, {
        id: form.id,
        payload: form
      })
    },

    /**
     * Prepara los datos antes de enviarlos al backend.
     *
     * Prepara los datos necesarios como la fecha o los roles y permisos que
     * se deben procesar en el backend.
     */
    prepareInput () {
    },

    resetForm () {
      document.activeElement.blur()
      this.$store.commit(`${API_MODULE}/setResourceToEdit`, null)
      this.$formulate.reset('empresaForm')
      this.formValues = formValues()
      this.response = response()

      this.modal = false
    },

    onInputFechaIngreso (value) {
      this.formValues.fecha_ingreso = moment(value).format('YYYY-MM-DD')
    },

    onShowModal () {
      this.modal = true
      this.$emit('input', this.modal)
    }
  }
}
</script>

<style>
.required_form {
  color: red;
}
.isLoading {
  max-height: 400px !important;
  overflow: hidden;
}
</style>
